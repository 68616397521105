@font-face {
  font-family: 'expansivabold';
  src: url('fonts/Expansiva-Bold.eot?#iefix') format('embedded-opentype'),
      url('fonts/Expansiva-Bold.woff') format('woff'),
      url('fonts/Expansiva-Bold.ttf')  format('truetype'),
      url('fonts/Expansiva-Bold.svg#Expansiva-Bold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'devbobczXBox';
  src: url('fonts/devbobczXBox.eot');
  src: url('fonts/devbobczXBox.eot?#iefix') format('embedded-opentype'),
      url('fonts/devbobczXBox.otf') format('opentype'), 
      url('fonts/devbobczXBox.ttf') format('truetype'), 
      url('fonts/devbobczXBox.svg#devbobczXBox') format('svg');

  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: '212_keyboardregular';
  src: url('fonts/212_keyboard-webfont.eot');
  src: url('fonts/212_keyboard-webfont.eot?#iefix') format('embedded-opentype'), 
      url('fonts/212_keyboard-webfont.woff2') format('woff2'), 
      url('fonts/212_keyboard-webfont.woff') format('woff'), 
      url('fonts/212_keyboard-webfont.ttf') format('truetype'), 
      url('fonts/212_keyboard-webfont.svg#212_keyboardregular') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'rajdhanilight';
  src: url('fonts/rajdhani-light-webfont.woff2') format('woff2'),
       url('fonts/rajdhani-light-webfont.woff') format('woff'),
       url('fonts/rajdhani-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}



.App {
  text-align: center;

}

.TitleScreen{
  margin: auto;
  height: 100%;
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  /* top: 30vh; */
  bottom: 0;
  -ms-touch-action: none;
}

.Tutorial{

  margin: auto;
  width: auto;
  position: absolute;
  left: 3em;
  right: 3em;
  top: 2em;
  bottom: 5em;
  -ms-touch-action: none;
  background-color: #000000E0;
  font-family: rajdhanilight, sans-serif;
  font-size: 1.2em;
  letter-spacing: 0.04em;
  overflow-y: auto;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.Tutorial h2, .Tutorial h3, .Tutorial h4{

  font-family: expansivabold, sans-serif;
  padding-bottom: 0.5em;
  letter-spacing: 0em;
}

.Tutorial p {
  margin-top: 0em;
  margin-bottom: 0em;
}

.Tutorial .row>.col, .Tutorial .row>[class^=col-] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: #111111;
  border: 2px solid #00000042;
}

.tutorialShow{
  font-family: rajdhanilight, sans-serif;
  font-size: 1.0em;
  top: 0.5em;
  right: 1em;
  position: absolute;

  animation: tutorialHide 60s forwards;
}

.tutorialEscape{
  font-family: rajdhanilight, sans-serif;
  right: 1em;
  position: absolute;
}

.help
{
  width: 1em;
  height: 1em;
}

.tableH4 {
  width: 100%;
  vertical-align: center;
  font-size: 1.4em;
}

.tableText{
  width: 100%;
  vertical-align: center;
}



.logo{
  display: inline-block;
  margin-top: 4em;
}

.logo h1{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  font-size: 4.2vw;
  filter: drop-shadow(5px 4px 6px rgb(0, 0, 0));
  background-image: url('testBlack.png');
  color: #ffffff55;

  background-clip: text;
	-moz-background-clip: text;
  -webkit-background-clip: text;

  animation: textclip 120s linear infinite, pulse 5s linear infinite;
  margin-bottom: 0em;
  background-size: 130%;
  background-repeat: repeat;

  /* filter: invert(1) drop-shadow(0px 0px 2px white) drop-shadow(0px 0px 1px white); */
  /* filter: drop-shadow(0px 0px 2px white) drop-shadow(0px 0px 1px white); */
  filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 16px darkgray);

}


@keyframes pulse{
  0%{
    filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 16px darkgray);
    /* background-color: red; */
  }
  50%{
    filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 6px darkgray);
  }
  100%{
    filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 16px darkgray);
  }
}



@keyframes textclip {
  from{
    background-position: 0px 0px;
  }
  
  to {
		background-position: -1920px -1080px;
	}
}

.developer{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  float: right;
  color: #ffffff66;
  filter: drop-shadow(0px 0px 16px darkgray);
  font-size: 0.8vw;
}

h2{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
}


.players
{
  margin-top: 3em;
  filter: drop-shadow(5px 4px 6px rgb(0, 0, 0));

}


.bigNumber
{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color:black;
  font-size: 2em;
  display: block;
}

.teamNumber{
  font-size: 0.5em;
}

.bigNumberWhite
{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color:white;
  font-size: 2em;
  display: block;
}

.bigNumberWhiteSmall
{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color:white;
  font-size: 1.4em;
  display: block;
  line-height: 1.9em;
}


.plaseInfo
{
  display: block;
  height: 7.5em;
}


.playerPhase
{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color:white;
  font-size: 1.2em;
  display: block;
  position: relative;
  width:inherit;
  top: 0em;
}

.phaseText{
  filter: drop-shadow(4px -1px 3px rgb(0, 0, 0));
  display: block;
}

.phaseTextSmaller
{
  display: block;
  font-size: 0.5em;
  color: white;
  filter:drop-shadow(0px 0px 2px #000000);
}



.phaseTextRdy{  
  animation: pulsate 1s ease infinite;
  filter: drop-shadow(4px -1px 3px rgb(0, 0, 0));
}


.phaseTextRdyCountdown{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  display: inline-block;
  font-size: 4em;
  margin-top: 0.4em;
  animation: pulsate 1s ease infinite;
  filter: drop-shadow(4px -1px 3px rgb(0, 0, 0));
}


.playerPhaseControls{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  display:block;
  background-color: #05050563;
  font-size: 0.9em;
  height: 8.4em;
  line-height: 1.3em;
}

.xbox{
  font-family: 'devbobczXBox';
  font-size: 2.3em;
  line-height: 0em;
  vertical-align: sub;

}

.pulsingPause{
  animation: pulsate 1s ease infinite;
  filter: drop-shadow(5px 4px 6px rgb(0, 0, 0));
}


.choseControlls
{
  display: inline-block;
  margin-top: 2.5em;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  filter: drop-shadow(5px 4px 6px rgb(0, 0, 0));
}

.toNextSymbol{
  display: inline-block;
  margin-top: 2.5em;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  width: 5em;
}


.results
{
  margin-top: 6em;
  filter: drop-shadow(5px 4px 6px rgb(0, 0, 0));
  width: 26em;
  margin: auto;

}


.resultsBox{
  vertical-align: middle;
  margin-bottom: 1em;
  display: inline-block;
  width: 100%;
  /* height: 2em; */

  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color:white;

  background-color: #008b8b36;

  /* animation: pulsate 1s ease infinite; */

  animation: resultsanim 1s ease-in;
}

.smallResultsBox{
  font-size: x-small;
}


.resultImg
{

  display: block;
  width: 3em;
  height: 3em;
  float:left;
  margin-right: 1em;
  clip-path: polygon(0em 1em, 0 3em, 2em 3em, 3em 2em, 3em 0em, 1em 0em);
  background-blend-mode: hard-light;
  background-image: url('/trike.png');
  background-position: center;
  background-size: 6em;
}

.resultName{
  font-size: 2em;
  float:left;
}

.resultTeam{
  font-size:0.5em;
}

.resultScore{
  font-size: 2em;
  float:right;
}


.lobbyBox{
  vertical-align: middle;
  margin: 1em;
  display: inline-block;
  width: 10em;
  height: 15em;
  clip-path: polygon(0em 2em, 0 15em, 8em 15em, 10em 13em, 10em 0em, 2em 0em);
  background-blend-mode: hard-light;
  background-image: url('/trike.png');
  background-position: center;
  background-position-y: -1.5em;
  
  background-size: cover;

  animation: joinanim 0.5s ease-in;
}

.lobbyBox .onlyKeyboard
{
  opacity: 0;
  position: fixed;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 2.5em;
  height: 10em;
  width: inherit;
}

.lobbyBox .onlyKeyboard .rotatedInfo
{
  position: absolute;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  transform: rotate(-45deg) translateX(-50%) translateY(-50%);
  left:50%;
  filter: drop-shadow(2px 4px 6px black);
}


.lobbyBox:hover .onlyKeyboard
{
  opacity: 1;
}





.lobbyBoxPlus{
  vertical-align: middle;
  margin: 1em;
  display: inline-block;
  width: 10em;
  height: 15em;
  background-color: rgb(32, 32, 32);
  clip-path: polygon(0em 2em, 0 15em, 8em 15em, 10em 13em, 10em 0em, 2em 0em);
  background-blend-mode: hard-light;
  background-position: center;
  background-size: cover;

  animation: joinanim 0.5s ease-in;
  
}

.lobbyBox:hover
{
  /* animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0); */
  /* perspective: 1000px; */
  cursor:not-allowed;
  
}

.lobbyBoxPlus:hover
{
  /* animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0); */
  /* perspective: 1000px; */
  cursor:help;
  

}



.lobbyBoxPlusText{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color:white;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: 0.5em;
}

.spacer2,.spacer1{
  margin-top: 2.3em;
}



.bottomHuds{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000080;
}

.PlayerInfo{
  vertical-align: middle;
  /* width: 10em; */
  /* height: 2.5em; */
  margin: 0.2em;
  padding: 0.3em;
  display: inline-block;
  /* background-color: #000000bf; */
  /* clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%); */
}

.PlayerNum{
  display: block;

}


.scoreSpan
{
  font-family: expansivabold,Arial, Helvetica, sans-serif;

}

.countDown{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: 1.5em;
}

.WinnFlash{

  position: absolute;
  width: 1.8em;
  height: 1.8em;
  background-image: url('/lightning.svg');
  animation: winner 1s ease infinite;
  margin-top: -0.2em;
  margin-left: -0.25em;
}


.ColorDot{
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  line-height: 0.9em;
  display: inline-block;
  vertical-align: top;
  margin: 0.3em;

}

.ColorDotLine
{
  height: 1.7em;
}


.ColorDotText{
  position: absolute;
  margin-top:0.3em;
  width: 2em;
  color: white;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: x-small;
  z-index: 5;

}


.PowerUp{
  width: 1.5em;
  height: 1.5em;
  margin: 0.1em;
  background-color: black;
  border: 1px solid #444444;
  /* clip-path: circle(65.6% at 50% 50%); */
}

.powerupWithCount
{
  display: inline-block;
}

.overdriveCount
{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  font-size: medium;
  position: relative;
  left: -0.6em;
  top: -0.5em;
}

.animuj1s{
  animation: powerupAnimation 1s 1;
}

.animuj5s{
  animation: powerupAnimation 5s 1;
}

.animuj10s{
  animation: powerupAnimation 10s 1;
}

.animuj15s{
  animation: powerupAnimation 15s 1;
}

.animuj20s{
  animation: powerupAnimation 20s 1;
}

.animuj30s{
  animation: powerupAnimation 30s 1;
}

@keyframes powerupAnimation{
  0%{
    clip-path: circle(100.0% at 50% 50%);
    /* background-color: red; */
  }
  5%{
    clip-path: circle(0.0% at 50% 50%);
  }
  97%{
    border: 1px solid #444444;
  }
  98%{
    border: 1px solid #ffffff;
  }
  99%{
    border: 1px solid #444444;
  }
  100%{
    clip-path: circle(60.0% at 50% 50%);
    /* background-color: rgb(0, 255, 0); */
    border: 1px solid #ffffff;
  }
}

.animuj10sback{
  animation: powerupAnimationBack 10s 1;
}

@keyframes powerupAnimationBack{
  0%{
    clip-path: circle(100.0% at 50% 50%);
    /* background-color: red; */
  }
  96%{
    clip-path: circle(0.0% at 50% 50%);
  }
  97%{
    border: 1px solid #444444;
  }
  98%{
    border: 1px solid #ffffff;
  }
  99%{
    border: 1px solid #444444;
  }
  100%{
    clip-path: circle(60.0% at 50% 50%);
    /* background-color: rgb(0, 255, 0); */
    border: 1px solid #ffffff;
  }
}



body {
  background-color: black;
  color: white;
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow: hidden;
  line-height: 1.15;
}


#canvas {
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  -ms-touch-action: none;
  /* velké zpomalení hry (pouze pro výkonné PC) */
  /* filter: drop-shadow(0px 0px 4px rgba(255,255,0,0.7)); */
}

#infocanvas {
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  -ms-touch-action: none;

}

#bgcanvas {
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  -ms-touch-action: none;


}


.bgImage1{
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: linear-gradient(-45deg, #440000, #000000, #050505, #30013a);
  /* background: linear-gradient(-45deg, #001373, #000000, #000000, #4c005d); */
  background-size: 400% 400%;
  animation: gradient 60s ease infinite;

}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes pulsate  {
	0% {
		opacity: 1.0;
	}
	50% {
		opacity: 0.0;
	}
	100% {
		opacity: 1.0;
	}
}


@keyframes winner {
  0%{
    filter: invert();
  }

  50%{
    filter: drop-shadow(2px 4px 6px black)
  }
}


@keyframes joinanim {
  0%{
    opacity:0;
    width: 0em;
    transform: translateY(-1000%);
  }

  90%{
    opacity:1;
    transform: translateY(-30%);
  }

  100%{
    opacity:1;
    transform: translateY(0);
  }
}


@keyframes resultsanim {
  0%{
    opacity:0;
  }

  100%{
    opacity:1;
  }
}


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}


@keyframes tutorialHide {
  0%{
    opacity:1;
  }

  80%{
    opacity:1;
  }

  100%{
    opacity:0.0;
  }
}

@keyframes playerHide {
  0%{
    opacity:1;
  }

  80%{
    opacity:1;
  }

  100%{
    opacity:0;
  }
}

.bgImage2{
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-image: url('test.png');
  opacity: 0.08;
}

a.devLink
{
  text-decoration: none;
  color: ffffff55;

}

a.devLink:visited
{
  color: #ffffff55;
}

a.devLink:hover
{
  color: #1da1f2;
}


.shareAndDonation
{
  display: block;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color: white;
  position: fixed;
  bottom: 7em;
  left: 0;
  right: 0;
}

.shareAndDonationEncapsuler
{
  margin: 0.5em;
  display: inline-flex;
  height: 2em;
}

.donateImg
{
  height:2em;
}

.shareButton {
  height: 2em;
  width: 2em;
  display: block;
  cursor: pointer;
  background-size: cover;
}


.twButton
{
    background-image: url('/twitterShare.svg');
}

.fbButton
{
    background-image: url('/fbShare.svg');
}

.vkButton
{
    background-image: url('/vkShare.svg');
}

.itchLink
{
    background-image: url('/itchLink.svg');
}

.musicPlayer
{
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color: white;
  position: fixed;
  float: left;
  margin:0.5em;
  display: inline-flex;
  left: 0;
  z-index: 5;
}

.gameUpdateAvailible
{
  display: block;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  color: white;
  filter: drop-shadow(5px 4px 6px rgb(0, 0, 0));
  position: fixed;
  bottom: 12em;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0.5em;
}

.gameUpdateButton{
  background-color: #ff00bdad;
  display: inline-block;
  padding: 0.5em;
  margin: 0.5em;
  cursor: pointer;
}

.gameUpdateButton:hover{
  background-color: white;
  color: black;
}



.withAutoHide:not(:hover)
{
  animation: tutorialHide 10s forwards;
}

.withAutoHide:hover
{
  opacity: 1;
}



.playButton
{
  /* background-image: url('/play.svg'); */
  background-repeat: no-repeat;
  height: 2em;
  width: 2em;
  background-size: cover;
  cursor: pointer;
}

.nextButton
{
  background-image: url('/next.svg');
  background-repeat: no-repeat;
  height: 2em;
  width: 2em;
  background-size: cover;
  cursor: pointer;
}

.plusminusButton{
  height: 1em;
  width: 1em;
  cursor: pointer;
}

.songName
{
  font-size: 1.1em;
  line-height: 1em;
  padding-left: 0.5em;
  text-align: left;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


@media only screen and (max-height: 900px) {
  .shareAndDonation
  {
    display: none;
  }

}



.cookies{
 
  display: block;
  font-family: monospace;
  filter: drop-shadow(5px 4px 6px rgb(0, 0, 0));
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 2em;
  color: #464646;
}

.cookies:hover{
 
  color: #ffffff;
}


.arrow{
  width: 0.7em;
  height: 0.7em;
  margin-left: 0.1em;
  margin-right: 0.1em;
}

.arrowUp{
  display: block;
  width: 100%;
  height: 0.4em;
  margin: 0.1em;
}

.arrowDown{
  display: block;
  width: 100%;
  height: 0.4em;
  margin: 0.1em;
  animation: pulsate 0.5s ease infinite;
}


.keyJoin{
  font-family: "212_keyboardregular";
  font-size: 2.5em;
  letter-spacing: -0.17em;
  line-height: 0em;
  margin-left: -0.3em;
  margin-top: 0.8em;
}

.xboxJoin{
  font-family: 'devbobczXBox';
  font-size: 3em;
  line-height: 0em;
  margin-top: 0.7em;
  filter: drop-shadow(0px 0px 2px white);
  vertical-align: middle;
}


.emptyKey{
  display: inline-block;
  background-image: url('/emptyKey.svg');
  font-family: monospace;

  width: 2.1em;
  height: 2.1em;
  text-align: center;
  background-size: cover;
  margin:0.2em;
  line-height: 2.0em;
  filter: drop-shadow(0px 0px 2px white);
  /* filter: drop-shadow(0px 0px 2px white); */
}

.keyLetter
{
  font-size: 1.6em;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.playerType
{
  position: fixed;
  font-family: expansivabold,Arial, Helvetica, sans-serif;
  margin-top: 1.6em;
  width: inherit;
  z-index: -1;
}

.playerTypeImg
{
  opacity: 0.1;
  height: inherit;
  float: right;
  margin: 1.6em;
  filter: invert(1);
}

.keyDiv{
  line-height: 0.2em;
  height: 0.4em;
}

.keySpan
{
  display: inline-block;
  width: 1em;
  height: 0.4em;
  margin-left: 0.2em;
  margin-right: 0.2em;
}


/* DivTable.com */
.divTable{
	display: table;
  width: 80%;
  margin: auto;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
}
.divTableCell, .divTableHead {
	border: 1px solid #999999;
	display: table-cell;
  padding: 0.5em 0.5em;
  vertical-align: middle;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}


@media (min-width: 1200px)
{
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1500px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2e2e2e; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}